import React from "react";
import { Helmet } from "react-helmet";
import { Header } from "../layout/blog";
import "./index.css";
import icon from "../assets/images/neocedrus.png";
import ogImg from '../assets/images/ogImg.png'


const blog = () => {
  let url = "https://neocedrus.com/blog";
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog - neoCedrus</title>
        <meta name="title" content="neoCedrus - Blog" />
        <meta
          name="description"
          content="We talk about groundbreaking topics"
        />
        <link rel="canonical" href={url} />
        <meta name="image" content={icon} />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="neoCedrus - Blog" />
        <meta
          property="og:description"
          content="Student-led Sotware Development Unit at Al Akhawayn University"
        />
        <meta property="og:image" content="https://cdn.neocedre.com/neocedrus-landing/assets/ogImg.png" />
        <meta content="https://cdn.neocedre.com/neocedrus-landing/assets/ogImg.png" property="og:image:type" />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content="neoCedrus" />

        <meta name="twitter:card" content="summary" />
      </Helmet>
      <Header></Header>
      {/* <Article></Article> */}
      <div className="container px-5 py-32 mx-auto lg:px-32">
        <div className="flex flex-col w-full mb-2 text-left ">
          <h1 className="mb-2 text-3xl font-black tracking-tightertext-black lg:text-8xl md:text-4xl">
            {" "}
            Coming Soon...{" "}
          </h1>
        </div>
      </div>
    </>
  );
};

export default blog;
